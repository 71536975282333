import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import slugify from 'react-slugify'
import styled from 'styled-components';


const StyleWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 0;
  position: relative;
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${({ theme }) => theme.media.desktop} {
    width: 100%;  
    height: auto;
    position: relative;   
    padding-bottom: 40px;
    font-family: ${({ theme }) => theme.font.family.Montserrat};
    background-color: ${({ theme }) => theme.grey};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
`;
const StyledInner = styled.div`
  width: 100%;

  s &:nth-child(2) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  background-color: white;
  ${({ theme }) => theme.media.desktop} {
    width: 75%;
    height: auto;

    max-width: 1450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const StyledTitle = styled.div`
  width: 75%;
  margin: 20px 0;
  
`;

const NumberDate = styled.div`
  width: 55px;
  height: 60px;
  margin-top: 15px;
  margin-left: 10px;
  border: 1px black solid;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
  background-color: ${({ theme }) => theme.honey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const DayDate = styled.p`
  width: auto;
  height: auto;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
`;
const Month = styled.p`
  width: auto;
  height: auto;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.5px;
`;
const StyledNews = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    margin-left: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 0;
    ;
  }
`;

const StyleImg = styled.div`
  ${({ theme }) => theme.media.desktop} {
    width: auto;
    max-width: 30%;
    height: 100%;
    border-radius: 5px;
    margin: 30px 10px 30px 35px ;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
   
  }
`;

const Styled = styled.div`
  width: 90%;
  margin-top: 10px;
  ${({ theme }) => theme.media.desktop} {
    width: 65%;
    height: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding:0 10px;
    
  }
`;

const StyledDesc = styled.p`
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: 500;
  line-height: 1.4;
  margin: 5px 0 15px 0;
  ${({ theme }) => theme.media.desktop} {
    width: 80%;
    height: auto;
    margin-top: 10px;
    margin-left: 10px;
    padding-left: 5px;
    justify-content: flex-start;
    align-items: center;
    font-size: ${({ theme }) => theme.font.size.xs};
  }
`;

const NewsTitle = styled.div`
  width: 95%;
  height: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 12px;
  margin-bottom: 12px;
  line-height: 1.6;
  padding-left: 10px;
  font-size: ${({ theme }) => theme.font.size.s};
  border-left: 2px solid ${({ theme }) => theme.primary};
  font-weight: 600;
  ${({ theme }) => theme.media.desktop} {
    display: flex;
    width: 50%;
    height: 35px;
    margin-top: 10px;
    margin-left: 10px;
    padding-left: 10px;
    justify-content: flex-start;
    align-items: center;
    font-size: ${({ theme }) => theme.font.size.s};
    border-left: 2px solid ${({ theme }) => theme.primary};
  }
`;

const Title = styled.div`
  width: 90%;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  margin: 20px 10px;
  border-left: 2px solid ${({ theme }) => theme.primary};
  font-size: ${({ theme }) => theme.font.size.s};
  ${({ theme }) => theme.media.desktop} {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    height: 35px;
    margin: 20px 0px 30px 10px;
    padding-left: 10px;
    font-size: ${({ theme }) => theme.font.size.s};
    border-left: 4px solid ${({ theme }) => theme.honey};
  }
`;

const StyledDate = styled.div`
  width: 100%;
  ${({ theme }) => theme.media.desktop} {
    width: 50px;
    height: auto;
  }
`;
const Imgs = styled.img`
  width: 35%;
  min-width: 200px;
  height: auto;
  min-height: 200px;
  object-position: 0 50% !important;
  max-height: 300px;
  object-fit: scale-down;
  padding: 10px;
  position: relative;

`;
const months = [
  { id: '12', name: 'GRU' },
  { id: '11', name: 'LIS' },
  { id: '10', name: 'PAZ' },
  { id: '09', name: 'WRZ' },
  { id: '08', name: 'SIE' },
  { id: '07', name: 'LIP' },
  { id: '06', name: 'CZE' },
  { id: '05', name: 'MAJ' },
  { id: '04', name: 'KWI' },
  { id: '03', name: 'MAR' },
  { id: '02', name: 'LUT' },
  { id: '01', name: 'STY' }
];


const NewsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsNews(sort: { fields: date, order: DESC }) {
        nodes {
          id
          title
          description
          date(formatString: "DD-MM-YYYY")
          fotoNews {
            fluid(maxWidth: 1600) {
              ...GatsbyDatoCmsFluid_tracedSVG
            }
            url
            filename
          }
        }
      }
    }
  `);

  return (
    <StyleWrapper>
      <StyledTitle>
        <Title>
          <h1>Aktualności</h1>
        </Title>
      </StyledTitle>

      {data.allDatoCmsNews.nodes.map(allDatoCmsNews => (

        <StyledInner key={allDatoCmsNews.id}>
        <div id={slugify(allDatoCmsNews.title, { delimiter: '_' })} />
          <StyledNews>
            <StyledDate>
              <NumberDate>
                <DayDate>{allDatoCmsNews.date.slice(0, 2)}</DayDate>
                <Month>{months.find(month => month.id === allDatoCmsNews.date.slice(3, 5)).name}</Month>
                <DayDate>{allDatoCmsNews.date.slice(6, 10)}</DayDate>
              </NumberDate>
            </StyledDate>

            <StyleImg>
              {allDatoCmsNews.fotoNews.map(fotoNews => (

                            <Imgs src={fotoNews.url} alt={fotoNews.filename} key={fotoNews.filename}/>

                ))}
            </StyleImg>

            <Styled>
              <NewsTitle>{allDatoCmsNews.title}</NewsTitle>
              <StyledDesc>{allDatoCmsNews.description}</StyledDesc>
            </Styled>
          </StyledNews>
        </StyledInner>

      ))}
    </StyleWrapper>
  );
};

export default NewsPage;
